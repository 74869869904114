body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100vh;
    width: 100vw;
}

@font-face {
    font-family: 'Roboto400';
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto900';
    src: url('./assets/fonts/Roboto/Roboto-Black.ttf');
}

@font-face {
    font-family: 'Roboto700';
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
}
@font-face {
    font-family: 'Roboto300';
    src: url('./assets/fonts/Roboto/Roboto-Light.ttf');
}
@font-face {
    font-family: 'Roboto500';
    src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
}
@font-face {
    font-family: 'Roboto100';
    src: url('./assets/fonts/Roboto/Roboto-Thin.ttf');
}
